.fontsemibold {
  font-family: Merriweather Sans;
  font-weight: 600;
}

.bold {
  font-weight: 700 !important;
}

.fontbold {
  font-family: Merriweather Sans;
  font-weight: 700;
}

.fontmedium {
  font-family: Merriweather Sans;
  font-weight: 500;
}

.fontreg {
  font-family: Merriweather Sans;
}

.f160 {
  font-size: 10em;
  letter-spacing: 0.2px;
}

.f25() {
  font-size: 1.563em;
  letter-spacing: 0.2px;
  line-height: 1.8em;
}

.f80() {
  font-size: 5em;
  letter-spacing: 0.2px;
}

.f60() {
  font-size: 3.75em;
  letter-spacing: 0.2px;
  line-height: 1.5em;
}
.f90() {
  font-size: 5.625em;
  letter-spacing: 0.2px;
  line-height: 1.222em;
}

.f80() {
  font-size: 5em;
  letter-spacing: 0.2px;
  line-height: 1.375em;
}

.f75() {
  font-size: 4.688em;
  letter-spacing: 0;
}

.f65() {
  font-size: 4.063em;
  letter-spacing: 0.2px;
  line-height: 1.538em;
}

.f55() {
  font-size: 3.438em;
  letter-spacing: 0.2px;
  line-height: 1.545em;
}

.f22() {
  font-size: 1.375em;
  letter-spacing: 0.2px;
  line-height: 1.455em;
}

.f26() {
  font-size: 1.625em;
  letter-spacing: 0.2px;
  line-height: 1.154em;
}

.f24() {
  font-size: 1.5em;
  letter-spacing: 0.25px;
  line-height: 1.417em;
}

.f20() {
  font-size: 1.25em;
  letter-spacing: 0.2px;
  line-height: 1.625em;
}

.f43 () {
  font-size: 2.688em;
  letter-spacing: 0.2px;
  line-height: 1.233em;
}

.f32 () {
  font-size: 2em;
  letter-spacing: 0.25px;
}

.f30 () {
  font-size: 1.875em;
  line-height: 1.333em;
  letter-spacing: 0.25px;
}

.f50() {
  font-size: 3.125em;
  letter-spacing: 0.2px;
  line-height: 1.2em;
}

.f46() {
  font-size: 2.875em;
  letter-spacing: 0.2px;
  line-height: 1.5em;
  letter-spacing: 1px;
}

.f18() {
  font-size: 1.25em;
  letter-spacing: 0.25px;
  line-height: 1.556em;
}

.f16() {
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.625em;
}

.f15() {
  font-size: 0.938em;
  letter-spacing: 0.25px;
  line-height: 1.667em;
}

.f14() {
  font-size: 0.875em;
  letter-spacing: 0.25px;
  line-height: 1.714em;
}
.f12() {
  font-size: 0.75em;
  letter-spacing: 0.25px;
  line-height: 2.5em;
}

.f28() {
  font-size: 1.75em;
  letter-spacing: 0.25px;
  line-height: 1.357em;
}

.mf20() {
  font-size: 2em;
  line-height: 1.2em;
  letter-spacing: 0.25px;

  //18
}

.f28 {
  font-size: 1.75em;
  letter-spacing: 0.12px;
  line-height: 1.786em;
}

.mf18 {
  font-size: 1.8em;
  letter-spacing: 0.2px;
  line-height: 1.556em;
}
.mf16 {
  font-size: 1.6em;
  letter-spacing: 0.25px;
  line-height: 1.625em;
}

.mf22 {
  font-size: 2.2em;
  letter-spacing: 0.25px;
  line-height: 1.364em;
}

.mf24 {
  font-size: 2.4em;
  letter-spacing: 0.2px;
  line-height: 1.5em;
}

.mf28 {
  font-size: 2.8em;
  letter-spacing: 0.2px;
  line-height: 1.2em;
}

.mf25 {
  font-size: 2.5em;
  letter-spacing: 0.2px;
  line-height: 1.2em;
}

.mf14() {
  font-size: 1.4em;
  letter-spacing: 0.25px;
  line-height: 1.714em;
}

.mf13() {
  font-size: 1.3em;
  letter-spacing: 0.25px;
  line-height: 1.538em;
}

.mf12 {
  font-size: 1.2em;
  letter-spacing: 0.25px;
  line-height: 2.5em;
}

.mf12() {
  font-size: 1.2em;
  letter-spacing: 0.25px;
  line-height: 1.833em;
}

.mf10() {
  font-size: 1.3em;
  letter-spacing: 0.25px;
  line-height: 1.538em;
}

.mf50 () {
  font-size: 5em;
  letter-spacing: 0.25px;
  padding-top: 1.16em;
}

.bShadow3616 {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.color (@color) {
  color: @color;
}

.deskPaddding {
  padding-left: 6.25em;
  padding-right: 6.25em;
}

.deskPadddingLeft {
  padding-left: 6.25em;
}

.heading {
  font-size: 2.625em;
  letter-spacing: 0.2px;
  color: #525252;
  .fontbold();

  //42
}

.f20 {
  font-size: 1.25em;
  letter-spacing: 0.25px;
  line-height: 1.591em;

  //20
}

.t-c {
  text-align: center;
}

.c0d {
  color: #0dad46;
}

.c3 {
  color: #333333;
}
.c3b {
  color: #3bb859;
}

.ced {
  color: #ed1c24;
}
.c1a {
  color: #1a1818;
}

.c52 {
  color: #525252;
}

.cw {
  color: #ffffff;
}
.c9a {
  color: #9a9a9a;
}

.mbPadding {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.mbheading {
  font-size: 2em;
  letter-spacing: 0.2px;
  color: #525252;
  .fontbold();
  text-align: center;
}
