@import url("../../common.less");
.sticky-content {
  position: fixed;
  top: 20%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  cursor: pointer;

  .rotate {
    writing-mode: vertical-rl;
  }
  .enquire {
    background-color: #333333;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .text {
      color: #ffffff;
      .f25();
      .fontreg;
      transform: rotate(180deg);
      padding: 1.68em 0.56em 1.68em 0.56em;
    }
  }
}

@media (max-width: 1024px) {
  .sticky-content {
    position: fixed;
    top: 20%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;

    .rotate {
      writing-mode: vertical-rl;
    }
    .enquire {
      background-color: #333333;
      cursor: pointer;

      .text {
        color: #ffffff;
        .f25();
        .fontreg;
        transform: rotate(180deg);
        padding: 2.68em 0.76em 2.68em 0.76em;
      }
    }
  }
}

@media (max-width: 1023px) {
  .sticky-content {
    top: initial;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;

    .rotate {
      writing-mode: initial;
    }
    .enquire {
      background-color: #0e993d;
      cursor: pointer;
      width: 100%;
      -webkit-box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.16);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;

      .text {
        color: #ffffff;
        .mf14();
        .fontreg;
        .t-c();
        transform: rotate(0);
        padding: 1em 0 1em 0;
      }
    }
  }
}

@primary-color: #1DA57A;