.ppsec1 {
  padding-left: 6.25em;
  padding-right: 6.25em;
  height: 6em;
  width: 100%;
  background-image: url("../../images/aboutus/bannerbg2560.png");
  background-position: center;
  background-size: cover;
}

.container {
  padding-top: 6em;
}

.container a {
  color: #3bb859;
  text-decoration: underline;
}

.title {
  margin-bottom: 20px;
  text-underline-offset: 5px;
}

.termsHeader {
  margin-left: 10px;
}

.headerText {
  margin-bottom: 5px;
}

.underLineText {
  text-decoration: underline;
}

ol {
  margin-top: 10px;
  padding-top: 5px;
}

ul,
ol {
  padding-left: 20px;
}

ul li,
ol li {
  padding-bottom: 5px;
  line-height: 18px;
  text-align: justify;
}

.font14 {
  font-size: 14px !important;
}
.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}

.text80 {
  opacity: 0.8;
}

.bold-text {
  font-weight: bold;
}

p {
  font-size: 14px !important;
  line-height: 18px;
  text-align: justify;
}

ul {
  list-style-type: none;
}

.sqauare-list {
  list-style-type: square;
}

.circle-list {
  list-style-type: disc;
}

@primary-color: #1DA57A;