.App {
  text-align: start;
}

p {
  margin: 0;
}
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.marginMiddleHomeContainer {
  margin-top: 6.188em;
}

.toast.toast-success {
  background-color: #f7f7f7 !important;
  color: #000000 !important;
  font-size: 1.2em !important;
}

.mw-f {
  max-width: 100% !important;
}

.fluid {
  max-width: 100%;
  height: auto;
}

.loader {
  height: 10em;
  width: 10em;
}

@media (max-width: 1023px) {
  .marginMiddleHomeContainer {
    margin-top: 5em;
  }
  .loader {
    height: 10em;
    width: 10em;
  }
}

@primary-color: #1DA57A;