@import url("../../common.less");

.pagenotfoundsec1 {
  background-image: url("../../images/404/bg.jpg");
  background-position: center;
  background-size: cover;
  height: calc(100vh - 5.188em);

  background-position: top center;

  p {
    .fontsemibold();
    .cw();
    .f30 ();
    pointer-events: none;
    padding-top: 1em;
  }

  h1 {
    font-size: 26.688em;
    .cw();
    .fontbold();
    line-height: 1;
    padding: 0.199em 0;
    margin: 0;
    letter-spacing: 0.2px;
    pointer-events: none;

    animation: spooky 2s alternate infinite linear;
  }
}

@media (max-width: 1023px) {
  .pagenotfoundsec1 {
    padding: 7.5em 1.6em;
    height: calc(100vh - 18em);

    p {
      .mf20 ();
      line-height: 1.6em;
    }

    h1 {
      font-size: 15em;
    }
  }
}

@keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}

@primary-color: #1DA57A;