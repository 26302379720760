@import url("../../common.less");

.btn {
  background-color: #333333;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  .fontreg();
  .f18();
  padding: 1.167em 0;
  width: 11.111em;
  border-radius: 100px;
  overflow: hidden;

  border: 3px solid #3bb859;
}

.fournotbtn {
  width: 16.111em !important;
}

/* effect-1 styles */

.btn.effect-1 {
  transition: all 0.2s linear 0s;

  &:before {
    content: url("../../images/arrowright.png");

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
    top: 2px;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
  }

  &:hover {
    text-indent: -20px;
    color: white;
    -webkit-box-shadow: 0px 9px 12px 0px rgba(59, 184, 89, 0.3);
    -moz-box-shadow: 0px 9px 12px 0px rgba(59, 184, 89, 0.3);
    box-shadow: 0px 9px 12px 0px rgba(59, 184, 89, 0.3);

    .fa {
      text-indent: 0px;
    }

    &:before {
      opacity: 1;
      text-indent: 0px;
    }
  }
}

@media (max-width: 1520px) {
  .btn {
    width: 12.111em;
  }
}

@media (max-width: 1023px) {
  .btn {
    .mf14();
    padding: 0.857em 0;
    // width: 9.571em;
    width: 11.571em;
  }
}

@primary-color: #1DA57A;